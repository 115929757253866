@font-face {
  font-family: 'Avenir';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url('https://d2ez0ydy7hy5tw.cloudfront.net/avenir/heavy/font.woff2') format('woff2'), url('https://d2ez0ydy7hy5tw.cloudfront.net/avenir/heavy/font.woff') format('woff');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('https://d2ez0ydy7hy5tw.cloudfront.net/avenir/book/font.woff2') format('woff2'), url('https://d2ez0ydy7hy5tw.cloudfront.net/avenir/book/font.woff') format('woff');
}

@font-face {
  font-family: 'Financier Display';
  src: url('https://d2ez0ydy7hy5tw.cloudfront.net/financier-display/bold/font.eot');
  src: url('https://d2ez0ydy7hy5tw.cloudfront.net/financier-display/bold/font.eot?#iefix') format('embedded-opentype'),
  url('https://d2ez0ydy7hy5tw.cloudfront.net/financier-display/bold/font.woff2') format('woff2'),
  url('https://d2ez0ydy7hy5tw.cloudfront.net/financier-display/bold/font.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
